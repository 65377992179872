import React from 'react';
import styled from 'styled-components';
import { useStoreState } from 'easy-peasy';

import Nav from './Nav';
import Footer from './Footer';
import Toast from './toast/Toast';

const S = {};
S.Layout = styled.div`
  background-color: #111113;
  margin: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

S.LayoutWrapper = styled.main`
  margin: 0 auto;
  width: 90%;
  padding-bottom: 2rem;

  @media only screen and (min-width: 768px) {
    max-width: 744px;
    padding-bottom: 4rem;
  }

  @media only screen and (min-width: 1024px) {
    max-width: 960px;
  }
`;

const Layout = props => {
  const { isToastActive, activeToastType, activeToastDetails } = useStoreState(
    state => state.notifications,
  );

  return (
    <S.Layout>
      <Nav />
      {isToastActive && (
        <Toast type={activeToastType} details={activeToastDetails} />
      )}
      <S.LayoutWrapper>{props.children}</S.LayoutWrapper>
      <Footer />
    </S.Layout>
  );
};

export default Layout;
